import { lazy } from 'react';

import { NotFound } from 'pages/not-found/not-found';
import { ErrorPage } from 'components/error-handler/error-page';
import { GlobalSearchOpenAI } from 'pages/global-search/global-search-open-ai';
import { ConsoleAudit } from '../pages/console-audit/console-audit';
import { SastScan } from '../pages/sast-scan/sast-scan';
import { IacScan } from '../pages/iac-scan/iac-scan';
import { TrivyScan } from 'pages/trivy-scan/trivy-scan';
import { TrivyScanDetails } from 'pages/trivy-scan/trivy-scan-details/trivy-scan-details';
import { PRSecrets } from 'pages/pr-secrets/pr-secrets';
import { PRSecretsDetails } from 'pages/pr-secrets/pr-secrets-details/pr-secrets-details';

export const paths = {
  dashboard: '/',
  alerts: '/alerts',
  policies: '/policies',
  editPolicy: '/policy',
  tenantSettings: '/tenant-settings',
  generateToken: '/generate-token',
  tenantSettingsWatchHide: '/tenant-settings-watch-hide',
  entityGraph: '/entity-graph',
  profileBilling: '/profile-billing',
  connectors: '/connectors',
  connectorsDetails: `/connectors/:id`,
  feedbackSupport: '/feedback-support',
  documentation: '/documentation',
  packageDependencyTree: '/dependency-tree',
  packageDependencyTreeById: '/dependency-tree/:id',
  opensourceDependencies: '/opensource-dependencies',
  opensourceDependencyDetails: '/opensource-dependencies/*',
  reporting: '/reporting',
  reports: '/reports',
  teamVulnerabilityReport: '/reports/team-vulnerability-report',
  resources: '/resources',
  secrets: '/secrets',
  sast: '/sast',
  iac: '/iac',
  trivy: '/github/terraform',
  trivyDetails: '/github/terraform/:org/:repo/:pr_number/:check_run_id',
  prSecrets: '/github/secrets',
  prSecretsDetails: '/github/secrets/:org/:repo/:pr_number/:check_run_id',
  identities: '/identities',
  commitDetails: '/commit-details/:repoSlug/:sha',
  identityDetails: '/identity',
  assets: '/assets',
  assetsDetails: '/assets-details',
  artifactoryDetails: '/artifactory-details',
  teams: '/teams',
  teamsDetails: '/team',
  console: '/console-audit',
  branches: '/branches',
  userManagement: '/user-management',
  repositories: '/repositories',
  artifactory: '/artifactory',
  recommendationsStatus: '/recommendations-status',
  recommendationsOverview: '/recommendations',
  recommendationsOverviewIdentity: '/recommendations-identity',
  recommendationsOverviewAsset: '/recommendations-asset',
  recommendationsOverviewTeam: '/recommendations-team',
  recommendationDetails: '/recommendations/:recommendationId',
  recommendationUserDetails: '/recommendations/users/:id',
  recommendationTeamDetails: '/recommendations/teams/:id',
  recommendationTeamMemberDetails: '/recommendations/members/:id',
  complianceOverview: '/compliance-overview',
  complianceDetails: '/compliance-overview/:complianceId',
  compliancePolicyDetails:
    '/compliance-overview/:complianceId/:compliancePolicyId',
  globalSearch: '/global-search',
  globalSearchInsights: '/global-search-insights',
  signout: '/signout',
  notFound: '*',
};

export const generateRoutingList = () => {
  const Dashboard = lazy(() =>
    import('pages/dashboard/dashboard')
      .then((module) => ({
        default: module.Dashboard,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const EntityGraph = lazy(() =>
    import('pages/entity-graph/entity-graph')
      .then((module) => ({
        default: module.EntityGraph,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const Alerts = lazy(() =>
    import('pages/alerts/alerts')
      .then((module) => ({
        default: module.Alerts,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const Identities = lazy(() =>
    import('pages/identities/identities')
      .then((module) => ({
        default: module.Identities,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const IdentityDetails = lazy(() =>
    import('pages/identities/identity-details/identity-details')
      .then((module) => ({
        default: module.IdentityDetails,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const CommitDetails = lazy(() =>
    import('pages/identities/components/flow-graph/flow-graph')
      .then((module) => ({
        default: module.FlowGraph,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const Connectors = lazy(() =>
    import('pages/connectors/connectors')
      .then((module) => ({
        default: module.Connectors,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const ConnectorDetails = lazy(() =>
    import('pages/connectors/components/connector-details/connector-details')
      .then((module) => ({
        default: module.ConnectorDetails,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );
  const Policies = lazy(() =>
    import('pages/policies/policies')
      .then((module) => ({
        default: module.Policies,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const EditPolicy = lazy(() =>
    import('pages/edit-policy/edit-policy')
      .then((module) => ({
        default: module.EditPolicy,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const Assets = lazy(() =>
    import('pages/assets/assets')
      .then((module) => ({
        default: module.Assets,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const Artifactory = lazy(() =>
    import('pages/artifactory/artifactory')
      .then((module) => ({
        default: module.Artifactory,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const AssetDetails = lazy(() =>
    import('pages/assets/asset-details/asset-details')
      .then((module) => ({
        default: module.AssetDetails,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const ArtifactoryDetails = lazy(() =>
    import('pages/assets/asset-details/artifactory-details')
      .then((module) => ({
        default: module.ArtifactoryDetails,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const Branches = lazy(() =>
    import('pages/branches/branches')
      .then((module) => ({
        default: module.Branches,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const Teams = lazy(() =>
    import('pages/teams/teams')
      .then((module) => ({
        default: module.Teams,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const TeamsDetails = lazy(() =>
    import('pages/teams/components/team-details/team-details')
      .then((module) => ({
        default: module.TeamDetails,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const OpensourceDependencies = lazy(() =>
    import('pages/opensource-dependencies/opensource-dependencies')
      .then((module) => ({
        default: module.OpenSourceDependencies,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const OpensourceDependencyDetails = lazy(() =>
    import(
      'pages/opensource-dependencies/opensource-dependency-details/opensource-dependencies-details'
    )
      .then((module) => ({
        default: module.OpensourceDependencyDetails,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const Reporting = lazy(() =>
    import('pages/reporting/reporting')
      .then((module) => ({
        default: module.Reporting,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const Reports = lazy(() =>
    import('pages/reports/reports-list')
      .then((module) => ({
        default: module.ReportsList,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const TeamVulnerabilityReport = lazy(() =>
    import('pages/reports/team-vulnerability-report')
      .then((module) => ({
        default: module.TeamVulnerabilityReport,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const UserManagement = lazy(() =>
    import('pages/user-management/user-management')
      .then((module) => ({
        default: module.UserManagement,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const PackageDependencyTree = lazy(() =>
    import('pages/package-dependency-tree/package-dependency-tree')
      .then((module) => ({
        default: module.PackageDependencyTree,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const Secrets = lazy(() =>
    import('pages/secrets/secrets')
      .then((module) => ({
        default: module.Secrets,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const TenantSettings = lazy(() =>
    import('pages/tenant-settings/tenant-settings')
      .then((module) => ({
        default: module.TenantSettings,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const TenantSettingsWatchHide = lazy(() =>
    import('pages/tenant-settings-watch-hide/tenant-settings-watch-hide')
      .then((module) => ({
        default: module.TenantSettingsWatchHide,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const RecommendationsStatus = lazy(() =>
    import('pages/recommendations-status/recommendations-status')
      .then((module) => ({
        default: module.RecommendationsStatus,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const Recommendations = lazy(() =>
    import('pages/recommendations/recommendations').then((module) => ({
      default: module.Recommendations,
    })),
  );

  const RecommendationsIdentity = lazy(() =>
    import('pages/recommendations-identity/recommendations-identity').then(
      (module) => ({
        default: module.RecommendationsIdentity,
      }),
    ),
  );

  const RecommendationsAsset = lazy(() =>
    import('pages/recommendations-asset/recommendations-asset').then(
      (module) => ({
        default: module.RecommendationsAsset,
      }),
    ),
  );

  const RecommendationsTeam = lazy(() =>
    import('pages/recommendations-team/recommendations-team').then(
      (module) => ({
        default: module.RecommendationsTeam,
      }),
    ),
  );

  const RecommendationUserDetails = lazy(() =>
    import(
      'pages/recommendation-users/components/recommendation-user-details'
    ).then((module) => ({
      default: module.RecommendationUserDetails,
    })),
  );

  const RecommendationDetails = lazy(() =>
    import(
      'pages/recommendations-overview/components/recommendation-details'
    ).then((module) => ({
      default: module.RecommendationDetails,
    })),
  );

  const RecommendationTeamDetails = lazy(() =>
    import(
      'pages/recommendation-teams/components/recommendation-team-details'
    ).then((module) => ({
      default: module.RecommendationTeamDetails,
    })),
  );

  const RecommendationTeamMemberDetails = lazy(() =>
    import(
      'pages/recommendation-team-members/components/recommendation-details'
    ).then((module) => ({
      default: module.RecommendationTeamMemberDetails,
    })),
  );

  const ComplianceOverview = lazy(() =>
    import('pages/compliance-overview/compliance-overview')
      .then((module) => ({
        default: module.ComplianceOverview,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const ComplianceDetails = lazy(() =>
    import('pages/compliance-overview/compliance-details/compliance-details')
      .then((module) => ({
        default: module.ComplianceDetails,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const CompliancePolicyDetails = lazy(() =>
    import(
      'pages/compliance-overview/compliance-details/compliance-policy-details/compliance-policy-details'
    )
      .then((module) => ({
        default: module.CompliancePolicyDetails,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  const GlobalSearch = lazy(() =>
    import('pages/global-search/global-search')
      .then((module) => ({
        default: module.GlobalSearch,
      }))
      .catch(() => ({
        default: ErrorPage,
      })),
  );

  return [
    {
      id: 'dashboard',
      path: paths.dashboard,
      element: <Dashboard />,
      isRender: true,
    },
    {
      id: 'entity-graph',
      path: paths.entityGraph,
      element: <EntityGraph />,
      isRender: true,
    },
    {
      id: 'entity-graph',
      path: paths.entityGraph,
      element: <EntityGraph />,
      isRender: true,
    },
    {
      id: 'package-dependency-tree',
      path: paths.packageDependencyTreeById,
      element: <PackageDependencyTree />,
      isRender: true,
    },
    {
      id: 'alerts',
      path: paths.alerts,
      element: <Alerts />,
      isRender: true,
    },
    {
      id: 'connectors',
      path: paths.connectors,
      element: <Connectors />,
      isRender: true,
    },
    {
      id: 'connectorDetails',
      path: paths.connectorsDetails,
      element: <ConnectorDetails />,
      isRender: true,
    },
    {
      id: 'policies',
      path: paths.policies,
      element: <Policies />,
      isRender: true,
    },
    {
      id: 'edit-policy',
      path: paths.editPolicy,
      element: <EditPolicy />,
      isRender: true,
    },
    {
      id: 'identities',
      path: paths.identities,
      element: <Identities />,
      isRender: true,
    },
    {
      id: 'identityDetails',
      path: paths.identityDetails,
      element: <IdentityDetails />,
      isRender: true,
    },
    {
      id: 'commitDetails',
      path: paths.commitDetails,
      element: <CommitDetails />,
      isRender: true,
    },
    {
      id: 'assets',
      path: paths.assets,
      element: <Assets />,
      isRender: true,
    },
    {
      id: 'assetDetails',
      path: paths.assetsDetails,
      element: <AssetDetails />,
      isRender: true,
    },
    {
      id: 'artifactoryDetails',
      path: paths.artifactoryDetails,
      element: <ArtifactoryDetails />,
      isRender: true,
    },
    {
      id: 'branches',
      path: paths.branches,
      element: <Branches />,
      isRender: true,
    },
    {
      id: 'teams',
      path: paths.teams,
      element: <Teams />,
      isRender: true,
    },
    {
      id: 'artifactory',
      path: paths.artifactory,
      element: <Artifactory />,
      isRender: true,
    },
    {
      id: 'console',
      path: paths.console,
      element: <ConsoleAudit />,
      isRender: true,
    },
    {
      id: 'teamsDetails',
      path: paths.teamsDetails,
      element: <TeamsDetails />,
      isRender: true,
    },
    {
      id: 'opensource-dependencies',
      path: paths.opensourceDependencies,
      element: <OpensourceDependencies />,
      isRender: true,
    },
    {
      id: 'opensource-dependency-details',
      path: paths.opensourceDependencyDetails,
      element: <OpensourceDependencyDetails />,
      isRender: true,
    },
    {
      id: 'secrets',
      path: paths.secrets,
      element: <Secrets />,
      isRender: true,
    },
    {
      id: 'sast',
      path: paths.sast,
      element: <SastScan />,
      isRender: true,
    },
    {
      id: 'iac',
      path: paths.iac,
      element: <IacScan />,
      isRender: true,
    },
    {
      id: 'trivy',
      path: paths.trivy,
      element: <TrivyScan />,
      isRender: true,
    },
    {
      id: 'trivyDetails',
      path: paths.trivyDetails,
      element: <TrivyScanDetails />,
      isRender: true,
    },
    {
      id: 'pr-secrets',
      path: paths.prSecrets,
      element: <PRSecrets />,
      isRender: true,
    },
    {
      id: 'pr-secrets-details',
      path: paths.prSecretsDetails,
      element: <PRSecretsDetails />,
      isRender: true,
    },
    {
      id: 'globalSearch',
      path: paths.globalSearch,
      element: <GlobalSearch />,
      isRender: true,
    },
    {
      id: 'globalSearchInsights',
      path: paths.globalSearchInsights,
      element: <GlobalSearchOpenAI />,
      isRender: true,
    },
    {
      id: 'reporting',
      path: paths.reporting,
      element: <Reporting />,
      isRender: true,
    },
    {
      id: 'reports',
      path: paths.reports,
      element: <Reports />,
      isRender: true,
    },
    {
      id: 'team-vulnerability-report',
      path: paths.teamVulnerabilityReport,
      element: <TeamVulnerabilityReport />,
      isRender: true,
    },
    {
      id: 'userManagement',
      path: paths.userManagement,
      element: <UserManagement />,
      isRender: true,
    },
    {
      id: 'tenantSettings',
      path: paths.tenantSettings,
      element: <TenantSettings />,
      isRender: true,
    },
    {
      id: 'tenantSettingsWatchHide',
      path: paths.tenantSettingsWatchHide,
      element: <TenantSettingsWatchHide />,
      isRender: true,
    },
    {
      id: 'recommendationsStatus',
      path: paths.recommendationsStatus,
      element: <RecommendationsStatus />,
      isRender: true,
    },
    {
      id: 'recommendations-overview',
      path: paths.recommendationsOverview,
      element: <Recommendations />,
      isRender: true,
    },
    {
      id: 'recommendations-overview-identity',
      path: paths.recommendationsOverviewIdentity,
      element: <RecommendationsIdentity />,
      isRender: true,
    },
    {
      id: 'recommendations-overview-asset',
      path: paths.recommendationsOverviewAsset,
      element: <RecommendationsAsset />,
      isRender: true,
    },
    {
      id: 'recommendations-overview-team',
      path: paths.recommendationsOverviewTeam,
      element: <RecommendationsTeam />,
      isRender: true,
    },
    {
      id: 'recommendation-details',
      path: paths.recommendationDetails,
      element: <RecommendationDetails />,
      isRender: true,
    },
    {
      id: 'recommendation-user-details',
      path: paths.recommendationUserDetails,
      element: <RecommendationUserDetails />,
      isRender: true,
    },
    {
      id: 'recommendation-team-details',
      path: paths.recommendationTeamDetails,
      element: <RecommendationTeamDetails />,
      isRender: true,
    },
    {
      id: 'recommendation-team-member-details',
      path: paths.recommendationTeamMemberDetails,
      element: <RecommendationTeamMemberDetails />,
      isRender: true,
    },
    {
      id: 'compliance-overview',
      path: paths.complianceOverview,
      element: <ComplianceOverview />,
      isRender: true,
    },
    {
      id: 'compliance-details',
      path: paths.complianceDetails,
      element: <ComplianceDetails />,
      isRender: true,
    },
    {
      id: 'compliance-policy-details',
      path: paths.compliancePolicyDetails,
      element: <CompliancePolicyDetails />,
      isRender: true,
    },
    // {
    //   id: 'jit',
    //   path: paths.jit,
    //   element: <Jit />,
    //   isRender: true,
    // },
    {
      id: 'not-found',
      path: paths.notFound,
      element: <NotFound />,
      isRender: true,
    },
  ];
};
