/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  debounce,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { TableComponent } from 'components/table/table-component/table-component';
import { Colors } from 'constants/colors';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import { GlobalSearchResponse } from './components/global-search-response/global-search-response';
import { useSearchParams } from 'react-router-dom';
import { GLOBAL_SEARCH_OPENAI_TABLE } from 'constants/test-ids';

export const GlobalSearchOpenAI = () => {
  const apiContext = useContext(ApiContext);
  const [searchParams] = useSearchParams();
  // const [searchResult] = apiContext.baseApi?.useGetGlobalSearchResultMutation();

  // const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [keepQuery, setKeepQuery] = useState(false);
  // const [queryPhrase, setQueryPhrase] = useState('');
  // const [tableData, setTableData] = useState<any[]>([]);
  // const [tableColumns, setTableColumns] = useState<any[]>([]);
  // const [cellRenderer, setCellRenderer] = useState<any>();
  // const [isModalOpen, setIsModalOpen] = useState(true);
  // const [additionalLoader, setAdditionalLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [table, setTable] = useState<any>(undefined);
  const [prompt, setPrompt] = useState<any>('');
  const [searched, setSearched] = useState(false);

  // const closeModal = () => {
  //   setKeepQuery(false);
  //   setIsModalOpen(false);
  // };

  const [querySuggestions] =
    apiContext.baseApi?.useLazyGetQuerySuggestionsQuery();

  const [globalSearch, { isError: isGlobalError }] =
    apiContext.baseApi?.useLazyGetGlobalSearchDataQuery();

  // const setTableRows = () => {
  //   setTableData(table?.output?.data);
  // };

  // const setTableHeaders = (tableType: string, rowData: any[]) => {
  //   switch (tableType) {
  //     case 'users':
  //       if (rowData.some((user) => user.display_name !== null))
  //         setTableColumns(IdentitiesWithDisplayName);
  //       else setTableColumns(IdentitesWithLogin);
  //       break;
  //     case 'teams':
  //       if (rowData.some((team) => team.org)) setTableColumns(TeamsWithOrg);
  //       else setTableColumns(TeamsWithoutOrg);
  //       break;
  //     case 'repositories':
  //       if (rowData.some((repo) => repo.org)) setTableColumns(AssetOrg);
  //       else setTableColumns(WithoutAssetOrg);
  //       break;
  //   }
  // };

  // const setCorrespondingRenderer = (tableType: string, rowData: any[]) => {
  //   switch (tableType) {
  //     case 'users':
  //       if (rowData.some((user) => user.display_name !== null))
  //         setCellRenderer(IdentityRendererWithDisplayName);
  //       else setCellRenderer(IdentityRendererWithLogin);
  //       break;
  //     case 'teams':
  //       setCellRenderer(TeamsRenderObj);
  //       break;
  //     case 'repositories':
  //       setCellRenderer(AssetsRenderObj);
  //       break;
  //   }
  // };

  // const submitQuery = async (body: any) => {
  //   try {
  //     setAdditionalLoader(true);
  //     setIsEmpty(true);
  //     const submitResponse: any = await searchResult({
  //       parms: {
  //         page: 1,
  //         items_page: 30,
  //       },
  //       payload: body?.payload,
  //     });
  //     if (submitResponse?.data) {
  //       setTableHeaders(body?.tableSelection, submitResponse?.data?.data);
  //       setCorrespondingRenderer(
  //         body?.tableSelection,
  //         submitResponse?.data?.data,
  //       );
  //       setTableRows(body?.tableSelection, submitResponse?.data);
  //       if (submitResponse?.data?.data?.length) {
  //         setIsEmpty(false);
  //       }
  //       setQueryPhrase(submitResponse?.data?.english_phrase);
  //       setLoading(false);
  //       setKeepQuery(true);
  //     }
  //   } catch (err) {
  //     console.error('result error', err);
  //   }
  //   setAdditionalLoader(false);
  // };

  const getSuggestions = async (val: string) => {
    const suggestionData = await querySuggestions(val);
    setSuggestions(
      suggestionData?.data
        ? suggestionData?.data?.map((suggest: any) => suggest?.suggestion)
        : [],
    );
  };

  useEffect(() => {
    const search = async () => {
      if (searchParams.get('query')) {
        setPrompt(searchParams.get('query'));
        setSearched(true);
        setLoading(true);
        const globalSearchTable = await globalSearch(searchParams.get('query'));
        setTable(globalSearchTable.data);
        setLoading(false);
      }
    };
    search();
    getSuggestions('');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchQueryChange = debounce((val: string) => {
    setSearch(val);
    getSuggestions(val);
  }, 300);

  const submitFunction = async () => {
    setPrompt(search);
    setSearched(true);
    setLoading(true);
    const globalSearchTable = await globalSearch(search);
    setTable(globalSearchTable.data);
    setLoading(false);
  };

  return (
    <Box>
      {/* <Box mb={2}>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Box>
            <Typography variant="subtitle1">
              Start typing Users, Repositories, Teams, or Packages To Start
              Seeing Options{' '}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ width: '90%' }}>
            <Autocomplete
              // open={suggestions.length !== 0}
              options={suggestions}
              value={prompt}
              onInputChange={(e, val) => handleSearchQueryChange(val)}
              onChange={(e, val) => {
                if (val !== null) {
                  setPrompt(val);
                }
              }}
              // onKeyDown={(e) => {
              //   setPrompt(search);
              //   if (e.code === 'Enter') {
              //     submitFunction();
              //   }
              // }}
              PaperComponent={(props) => (
                <Box
                  sx={{
                    background: Colors.blackPearl,
                    color: Colors.whiteTransparent07,
                  }}
                  {...props}
                />
              )}
              renderInput={(params) => (
                <StyledTextField placeholder="Enter Search Query" {...params} />
              )}
            />
          </Box>
          <Box
            sx={{
              width: '8%',
              background: Colors.purple,
              p: 1,
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: '600',
              cursor: 'pointer',
            }}
            onClick={() => submitFunction()}
          >
            Submit
          </Box>
        </Box>
      </Box> */}
      <Box sx={{ height: '50vh' }}>
        <ContainerWithLoader isLoading={loading} isError={false}>
          <GlobalSearchResponse output={table?.output} searched={searched}>
            <>
              <Typography
                variant="h3"
                sx={{
                  color: Colors.lightSilver,
                  mt: 3,
                  textTransform: 'capitalize',
                }}
              >
                {table?.input}
              </Typography>
              <Box>
                <TableComponent
                  isListLoading={false}
                  isListErrored={isGlobalError}
                  isEmptyList={table?.output?.data?.length === 0}
                  isListFetching={false}
                  dataTestId={GLOBAL_SEARCH_OPENAI_TABLE}
                  list={table?.output?.data}
                  tableColumns={table?.output?.output_schema?.map(
                    (schema: any, index: number) => {
                      return {
                        id: schema,
                        title: table?.output?.output_schema_names[index],
                        position: 'left',
                      };
                    },
                  )}
                  notPaginated
                />
              </Box>
            </>
          </GlobalSearchResponse>
        </ContainerWithLoader>
      </Box>
    </Box>
  );
};

export const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputLabel-root': {
    color: `${Colors.whiteTransparent07}!important`,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${Colors.whiteTransparent07}!important`,
  },
  '& .MuiOutlinedInput-input': {
    textFillColor: `${Colors.whiteTransparent07}!important`,
  },

  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: Colors.whiteTransparent07,
    },
  },
}));
